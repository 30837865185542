.my-navbar {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 20;
  background-color: #fff;
  display: block;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

  &-main {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include responsive-for(md) {
      display: none;
    }

    .logo {
      a {
        img {
          height: 50px;
        }
      }
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      // flex-wrap: wrap;
      // gap: 1rem;

      &-links {
        color: #242424;
        position: relative;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        padding: 0rem 1rem;
        padding-bottom: 7px;
        text-transform: uppercase;

        &::after {
          background-color: color(primary, base);
          content: "";
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          width: 0;
          height: 2px;
          transition: all 0.45s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }

        &:hover {
          &::after {
            opacity: 1;
            width: 15px;
          }
        }
      }

      &-dropdown {
        font-size: 14px;
        font-weight: 600;
        color: #242424;
        text-transform: uppercase;

        &-header {
          cursor: pointer;
          padding: 0 1rem;
          display: flex;
          gap: 6px;
          flex-direction: row;
          align-items: center;
        }
        .menu {
          position: absolute;
          display: none;
          margin: 0;
          z-index: 1000;
          padding: 0.5rem 0;
          list-style: none;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 0.25rem;
          min-width: 10rem;
          animation: slide-up 300ms ease-out forwards;

          .item {
            display: block;
            width: 100%;
            padding: 0.5rem 0.5rem;
            clear: both;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
          }
        }
        .arrow {
          transform: rotate(0deg);
          transition: transform 500ms;
        }
        &:hover {
          .arrow {
            transform: rotate(-180deg);
            transition: transform 500ms;
          }
          .menu {
            display: block;
          }
        }
      }

      .active {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          background-color: color(primary, base);
          width: 15px;
          left: 50%;
          bottom: 0;

          height: 2px;
        }
      }
    }
  }

  .mobile-nav {
    display: none;
    @include responsive-for(md) {
      display: flex;
    }
    padding: 0.5rem 0;
    justify-content: space-between;
    align-items: center;

    .show-nav {
      background: transparent;
      outline: none;
      border: none;
      color: #000;
      font-size: 2rem;
      padding: 0;
      margin: 0;
    }
  }
}

.mobile-sidebar {
  list-style: none;
  display: none;
  @include responsive-for(md) {
    display: flex;
  }
  flex-direction: column;
  padding: 0;
  .link-container {
    display: flex;
    padding: 0;
    margin: 0.5rem 0;
    flex-direction: column;
    font-weight: 500;
    font-size: 14px;

    gap: 1.5rem;
    a {
      color: #000;
    }
  }
  &-link {
    padding: 16px 20px;
    border-bottom: 1px solid #dee2e6;
    color: #000;
    font-weight: 600;
    &:hover {
      color: #000;
    }
  }

  ul {
    list-style: none;
  }

  .accordion-button {
    font-weight: 600;

    &:not(.collapsed) {
      color: color(primary, base);
      background-color: color(background-light);
    }
  }

  .top-border {
    border-top: 1px solid #dee2e6;
  }
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(1.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
