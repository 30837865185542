.home-introduction {
    padding: 4rem 0 2rem 0;
    text-align: center;
    display: flex;
    flex-direction: column;

    @include responsive-for(sm) {
        padding: 1.5rem 0;
    }

    .right-section {
        max-width: 550px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex: none;
        &-header {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .pill {
                padding: 5px 15px;
                background-color: color(primary, pill);
                border-radius: 40px;
                width: max-content;
                color: color(primary-text);
                font-weight: 500;
            }
        }
    }

    &-header {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 1rem;
        color: color(primary-text);
    }

    .blue-line {
        align-self: center;
        width: 70px;
        height: 2px;
        background-color: color(primary, base);
    }

    &-body {
        align-self: center;

        margin-top: 2rem;
        width: 90%;
        color: color(body-text);
    }
}
