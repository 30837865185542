/* 3.0 Heading Size*/
@keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
* {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

svg:not(:root) {
  overflow: unset;
}

html {
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
}

div {
  color: #242424;
}

body {
  margin: 0;
  font-weight: 400;
  background: #ffffff;
  color: #242424;
}

h1 {
  font-size: 3.75rem;
  line-height: 68px;
  font-weight: 700 !important;
}
@media all and (max-width: 768px) {
  h1 {
    font-size: 2.75rem;
    line-height: 3rem;
  }
}

h2 {
  font-size: 2.5rem;
  font-weight: 700 !important;
  color: #242424;
}

h3 {
  font-size: 1.5rem;
  font-weight: 700 !important;
  color: #242424;
}

h4 {
  font-size: 1.2rem;
  font-weight: 700 !important;
  color: #242424;
}

button:focus,
textarea:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}
a:hover {
  color: black;
}

thead {
  color: white;
  border-style: hidden !important;
}

th {
  font-weight: 500;
  font-size: 20;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

td {
  border: none !important;
  padding: 0.7rem !important;
}

.red {
  color: #e9292f;
}

p {
  color: #2e2e2e;
}

.my-navbar {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 20;
  background-color: #fff;
  display: block;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.my-navbar-main {
  padding: 1.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 768px) {
  .my-navbar-main {
    display: none;
  }
}
.my-navbar-main .logo a img {
  height: 50px;
}
.my-navbar-main .nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.my-navbar-main .nav-links {
  color: #242424;
  position: relative;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  padding: 0rem 1rem;
  padding-bottom: 7px;
  text-transform: uppercase;
}
.my-navbar-main .nav-links::after {
  background-color: rgb(35, 166, 223);
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  transition: all 0.45s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.my-navbar-main .nav-links:hover::after {
  opacity: 1;
  width: 15px;
}
.my-navbar-main .nav-dropdown {
  font-size: 14px;
  font-weight: 600;
  color: #242424;
  text-transform: uppercase;
}
.my-navbar-main .nav-dropdown-header {
  cursor: pointer;
  padding: 0 1rem;
  display: flex;
  gap: 6px;
  flex-direction: row;
  align-items: center;
}
.my-navbar-main .nav-dropdown .menu {
  position: absolute;
  display: none;
  margin: 0;
  z-index: 1000;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  min-width: 10rem;
  animation: slide-up 300ms ease-out forwards;
}
.my-navbar-main .nav-dropdown .menu .item {
  display: block;
  width: 100%;
  padding: 0.5rem 0.5rem;
  clear: both;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.my-navbar-main .nav-dropdown .arrow {
  transform: rotate(0deg);
  transition: transform 500ms;
}
.my-navbar-main .nav-dropdown:hover .arrow {
  transform: rotate(-180deg);
  transition: transform 500ms;
}
.my-navbar-main .nav-dropdown:hover .menu {
  display: block;
}
.my-navbar-main .nav .active {
  position: relative;
}
.my-navbar-main .nav .active::after {
  content: "";
  position: absolute;
  background-color: rgb(35, 166, 223);
  width: 15px;
  left: 50%;
  bottom: 0;
  height: 2px;
}
.my-navbar .mobile-nav {
  display: none;
  padding: 0.5rem 0;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 768px) {
  .my-navbar .mobile-nav {
    display: flex;
  }
}
.my-navbar .mobile-nav .show-nav {
  background: transparent;
  outline: none;
  border: none;
  color: #000;
  font-size: 2rem;
  padding: 0;
  margin: 0;
}

.mobile-sidebar {
  list-style: none;
  display: none;
  flex-direction: column;
  padding: 0;
}
@media all and (max-width: 768px) {
  .mobile-sidebar {
    display: flex;
  }
}
.mobile-sidebar .link-container {
  display: flex;
  padding: 0;
  margin: 0.5rem 0;
  flex-direction: column;
  font-weight: 500;
  font-size: 14px;
  gap: 1.5rem;
}
.mobile-sidebar .link-container a {
  color: #000;
}
.mobile-sidebar-link {
  padding: 16px 20px;
  border-bottom: 1px solid #dee2e6;
  color: #000;
  font-weight: 600;
}
.mobile-sidebar-link:hover {
  color: #000;
}
.mobile-sidebar ul {
  list-style: none;
}
.mobile-sidebar .accordion-button {
  font-weight: 600;
}
.mobile-sidebar .accordion-button:not(.collapsed) {
  color: rgb(35, 166, 223);
  background-color: rgba(35, 166, 223, 0.1);
}
.mobile-sidebar .top-border {
  border-top: 1px solid #dee2e6;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(1.5rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.footer {
  background-color: #081526;
  margin-top: auto;
}
.footer-main {
  padding-top: 3rem;
  padding-bottom: 4rem;
}
@media all and (max-width: 480px) {
  .footer-main {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 2rem;
  }
}
.footer-secondary {
  background-image: url(../assets/footer-bg.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding: 1rem 0;
}
.footer-secondary-main {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media all and (max-width: 480px) {
  .footer-secondary-main {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    padding: 1rem;
  }
}
.footer-secondary-main .copy-info {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}
.footer-secondary-main .right {
  padding: 0;
  display: flex;
  gap: 1.5rem;
  justify-content: flex-start;
  align-items: center;
}
.footer-secondary-main .right .link {
  color: #fff;
  font-size: 14px;
  transition: all 0.3s;
  font-weight: 300;
}
.footer-secondary-main .right .link:hover {
  color: rgb(35, 166, 223);
}
.footer__item {
  color: #fff;
}
@media all and (max-width: 576px) {
  .footer__item {
    margin-bottom: 2rem;
  }
}
.footer__item p {
  margin-top: 2rem;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
}
.footer__item-contact {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 300;
}
.footer__item-contact .company-name {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.footer__item-contact .location-info {
  color: #fff;
}
.footer__item-contact .phone-no {
  color: #fff;
  margin-top: 1rem;
}
.footer__item-contact .phone-no a {
  color: #fff;
}
.footer__item-contact .phone-no a:hover {
  border-bottom: 1px solid white;
}
.footer__item-heading {
  border-bottom: 1px solid white;
  margin-bottom: 1.5rem;
}
.footer__item-heading h4 {
  color: #fff;
  padding: 0.5rem 0;
  font-size: 20px;
  font-weight: 600;
  font-weight: 500;
}
.footer__item ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer__item ul li {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 1rem;
}
.footer__item ul li svg {
  font-size: 18px;
}
.footer__item ul li a {
  color: #cccccc;
  transition: all 200ms;
  padding-bottom: 2px;
}
.footer__item ul li a:hover {
  color: #fff;
  border-bottom: 1px solid white;
}
.footer__item-socials {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.footer__item-socials a {
  color: #8998c2;
  font-size: 20px;
  transition: all 0.3s;
}
.footer__item-socials a:hover {
  color: rgb(35, 166, 223);
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.info-container {
  margin: 4rem 0;
}
.info-container .body-content {
  font-size: 14px;
}
.info-container .body-content .sub-header {
  font-size: 14px;
  font-weight: 600;
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.error-image {
  margin: auto;
  max-width: 100%;
}
@media all and (max-width: 576px) {
  .error-image {
    height: 256px;
    width: 256px;
  }
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
}
.loading-container .loader {
  width: 48px;
  height: 48px;
  border: 5px solid rgb(35, 166, 223);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
}
.loading-container .loader:after {
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid rgb(35, 166, 223);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}
@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  60%, 100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes pulse {
  0%, 60%, 100% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
}

.pricing-contact {
  margin: 4rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.contact {
  margin: 3rem 0;
}
.contact-form {
  margin-bottom: 3rem;
  border: 1px solid #e5e5e5;
  padding: 2rem;
  border-radius: 4px;
}
@media all and (max-width: 992px) {
  .contact-form {
    margin: 2rem 0;
  }
}
.contact-form h2 {
  font-size: 24px;
  line-height: 2rem;
  font-weight: 700;
}
.contact-form .header-message {
  color: #2e2e2e;
  font-size: 12px;
  padding: 0;
  margin: 0;
}
.contact-form .submit-success {
  margin-top: 2rem;
  background-color: #d1e7dd;
  padding: 1rem;
  border: 1px solid #badbcc;
  border-radius: 6px;
}
.contact-form .submit-success p {
  color: #0f5132;
  margin: 0;
  padding: 0;
}
.contact-form-main {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.contact-form-main-item {
  width: 100%;
}
.contact-form-main-item label {
  font-size: 0.9rem;
  display: flex;
  margin-bottom: 0.3rem;
}
.contact-form-main-item input,
.contact-form-main-item textarea {
  font-size: 14px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #e5e7f3;
  background: #f7f9fb;
  padding: 0.5rem;
  color: #7a7c7f;
  margin-right: 0.5rem;
  font-size: 0.9rem;
  transition: all 300ms;
}
.contact-form-main-item input::-moz-placeholder, .contact-form-main-item textarea::-moz-placeholder {
  font-size: 12px;
}
.contact-form-main-item input::placeholder,
.contact-form-main-item textarea::placeholder {
  font-size: 12px;
}
.contact-form-main-item input:focus,
.contact-form-main-item textarea:focus {
  border-color: #9fa0a6;
  outline: none;
}
.contact-form-main-item--message {
  width: 100%;
}
.contact-form-main-item span {
  display: flex;
  font-size: 0.75rem;
  color: red;
}
.contact-form-main-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
@media all and (max-width: 576px) {
  .contact-form-main-row {
    flex-direction: column;
  }
}
.contact-form-main button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
  background-color: rgb(35, 166, 223);
  color: white;
  border: none;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1px;
  transition: all 200ms;
  border-radius: 25px;
}
.contact-form-main button:disabled {
  background-color: rgba(35, 166, 223, 0.6);
}
.contact-form-main button:not(:disabled):hover {
  background-color: rgb(20, 83, 110);
}
.contact-map {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid #e5e5e5;
  padding: 0.5rem;
  border-radius: 4px;
}
.contact-map h2 {
  font-size: 24px;
  line-height: 2rem;
  font-weight: 700;
}
.contact-map iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
@media all and (max-width: 576px) {
  .contact-map {
    height: 25rem;
  }
}

.contact-card {
  max-width: 550px;
  border: 1px solid #e5e5e5;
  padding: 1.5rem 2rem 3rem 2rem;
  border-radius: 4px;
}
.contact-card .bookmark-icon {
  text-align: center;
  font-size: 28px;
  color: #2e2e2e;
}
.contact-card h3 {
  text-align: center;
  margin: 2rem 0 0.3rem 0;
  font-weight: 600;
  font-size: 28px;
  padding: 0;
}
.contact-card-address {
  text-align: center;
  font-size: 14px;
  margin-bottom: 1rem;
  color: #2e2e2e;
}
.contact-card .contact-item {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-size: 14px;
}
.contact-card .contact-item-title {
  font-weight: 600;
}
.contact-card .contact-item-content a {
  text-decoration: none;
  color: #2e2e2e;
}

.scrolltop {
  background: rgb(35, 166, 223);
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  display: inline;
  position: fixed;
  border: none;
  outline: none;
  border-radius: 50%;
  bottom: 3rem;
  right: 3rem;
  display: inline-block;
  transition: visibility 0.5s ease-in-out;
  box-shadow: 1px 2px 5px rgba(139, 139, 139, 0.637);
  z-index: 99;
}
@media all and (max-width: 576px) {
  .scrolltop {
    right: 1.5rem;
  }
}

.notVisible {
  display: none;
  transition: visibility 0.5s ease-in-out;
}

.carousel-image-item {
  position: relative;
  height: 450px;
  width: 100%;
}
@media all and (max-width: 576px) {
  .carousel-image-item {
    height: 150px;
  }
}
.carousel-image-item img {
  height: 100%;
  width: 100% !important;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: left bottom;
     object-position: left bottom;
}

.home-introduction {
  padding: 4rem 0 2rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
}
@media all and (max-width: 576px) {
  .home-introduction {
    padding: 1.5rem 0;
  }
}
.home-introduction .right-section {
  max-width: 550px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: none;
}
.home-introduction .right-section-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.home-introduction .right-section-header .pill {
  padding: 5px 15px;
  background-color: rgba(54, 181, 134, 0.2);
  border-radius: 40px;
  width: -moz-max-content;
  width: max-content;
  color: #242424;
  font-weight: 500;
}
.home-introduction-header {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #242424;
}
.home-introduction .blue-line {
  align-self: center;
  width: 70px;
  height: 2px;
  background-color: rgb(35, 166, 223);
}
.home-introduction-body {
  align-self: center;
  margin-top: 2rem;
  width: 90%;
  color: #2e2e2e;
}

.home-featured {
  padding: 1rem 0 4rem 0;
}
.home-featured .featured-card {
  margin: 1rem auto;
  min-height: 400px;
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 24px;
  transition: all 200ms;
  padding: 32px 50px 40px;
  align-items: center;
  text-align: center;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
}
@media all and (max-width: 576px) {
  .home-featured .featured-card {
    max-width: 320px;
  }
}
.home-featured .featured-card:hover {
  box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.08);
}
.home-featured .featured-card-image img {
  max-height: 60px;
  max-width: 100%;
}
.home-featured .featured-card-header {
  font-size: 20px;
  font-weight: 700;
  color: #242424;
}
.home-featured .featured-card-body {
  color: #2e2e2e;
  line-height: 160%;
}
.home-featured .featured-card-link {
  margin-top: auto;
  color: rgb(35, 166, 223);
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
}

.about-us {
  padding: 6rem 0 0 0;
}
@media all and (max-width: 576px) {
  .about-us {
    padding: 3rem 0 0 0;
  }
}
.about-us-image {
  width: 100%;
}
.about-us-image img {
  width: 100%;
}
.about-us-body {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: center;
  justify-content: center;
}
@media all and (max-width: 576px) {
  .about-us-body {
    margin-left: 0;
    margin-top: 2rem;
  }
}
.about-us-body-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.about-us-body-header .info {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.about-us-body-header .info .line {
  width: 40px;
  height: 2px;
  background-color: rgb(35, 166, 223);
}
.about-us-body-header .info span {
  width: -moz-max-content;
  width: max-content;
  color: rgb(35, 166, 223);
  font-weight: 600;
  font-size: 14px;
}
.about-us-body-header h2 {
  font-size: 40px;
  font-weight: 600;
  color: #242424;
}
.about-us-body .content div {
  color: #2e2e2e !important;
  margin: 0;
}

.members {
  padding: 4rem 0;
}
.members h2 {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}
.members p {
  font-weight: 300;
  text-align: center;
  color: #2e2e2e;
  margin-bottom: 2rem;
}
.members-card {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  border-radius: 8px;
  outline: 1px solid rgba(57, 57, 57, 0.3);
  height: 100%;
  max-width: 300px;
  align-self: center;
}
@media all and (max-width: 576px) {
  .members-card {
    height: auto;
    margin: auto;
  }
}
.members-card img {
  width: 100%;
  height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center top;
     object-position: center top;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.members-card-information {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.8rem;
}
.members-card-information-header h4 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.members-card-information-header h5 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.news {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.news-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.news-header .date {
  font-size: 14px;
  color: rgb(35, 166, 223);
  padding-left: 10px;
}
.news-header .title {
  margin: 0;
  color: #242424;
}

.news-body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.news-body .content {
  color: #2e2e2e;
  line-height: 200%;
}

.information {
  margin: 3rem 0;
}
.information .blog-card {
  height: 100%;
  border-radius: 4px;
  outline: 1px solid #e5e5e5;
  padding: 0;
  display: flex;
  flex-direction: column;
  transition: all 200ms;
  margin: 1rem auto;
}
@media all and (max-width: 576px) {
  .information .blog-card {
    max-width: 300px;
  }
}
.information .blog-card:hover {
  box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.08);
}
.information .blog-card-image img {
  height: 260px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border: none;
}
.information .blog-card-body {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}
.information .blog-card-body-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.information .blog-card-body-header .date {
  font-size: 14px;
  color: rgb(35, 166, 223);
}
.information .blog-card-body-header .title {
  font-size: 20px;
  font-weight: 500;
  color: #242424;
}
.information .blog-card-body-content {
  color: #2e2e2e;
  margin: 0;
  padding: 0;
}
.information .blog-card-body-link {
  color: rgb(35, 166, 223);
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  margin-top: auto;
}

.news-latest {
  background: rgba(35, 166, 223, 0.1);
  border-radius: 8px;
  padding: 1rem;
  margin-left: 2rem;
}
@media all and (max-width: 576px) {
  .news-latest {
    margin-left: 0;
  }
}
.news-latest ul {
  list-style: none;
  margin: 0;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
}
.news-latest ul li a {
  color: #242424;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.news-latest ul li a svg {
  transition: all 200ms;
  margin-right: 10px;
}
.news-latest ul li a:hover svg {
  margin-left: 5px;
}

.breadcumb {
  height: 300px;
  display: flex;
  align-items: center;
}
@media all and (max-width: 576px) {
  .breadcumb {
    height: 200px;
  }
}
.breadcumb-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.breadcumb-container-breadcumb {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.breadcumb-container-breadcumb .home-link {
  text-decoration: none;
  color: #fff;
}
.breadcumb-container-breadcumb .home-link:hover {
  color: rgb(35, 166, 223);
}
.breadcumb-container-header {
  color: #fff;
  margin: 0;
  padding: 0;
}

.product-container {
  margin: 4rem 0;
  padding: 0;
}
.product-container .content-div {
  display: flex;
  flex-direction: row;
  padding: 3rem 0;
  gap: 2rem;
  font-size: 14px;
}
@media all and (max-width: 576px) {
  .product-container .content-div {
    flex-direction: column;
    padding: 2rem 0;
  }
}
.product-container .content-div:nth-child(even) {
  flex-direction: row-reverse;
}
@media all and (max-width: 576px) {
  .product-container .content-div:nth-child(even) {
    flex-direction: column;
    padding: 2rem 0;
  }
}
.product-container .content-div-details {
  flex: 1 1 55%;
  padding: 0 1rem;
}
.product-container .content-div-image {
  flex: 1 1 45%;
  padding: 0 1rem;
}
.product-container .content-div-image img {
  width: 100%;
}
.product-content {
  max-width: 100% !important;
}
.product-content img {
  max-width: 100%;
  height: auto;
}
.product-images {
  margin: 2rem 0 4rem 0;
  display: flex;
  flex-direction: row;
}
@media all and (max-width: 576px) {
  .product-images {
    flex-wrap: wrap;
  }
}
.product-images-container {
  padding: 0.5rem;
}
.product-images-container img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=App.css.map */