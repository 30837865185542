.product {
    &-container {
        margin: 4rem 0;
        padding: 0;

        .content-div {
            display: flex;
            flex-direction: row;
            padding: 3rem 0;
            gap: 2rem;
            font-size: 14px;

            @include responsive-for(sm) {
                flex-direction: column;
                padding: 2rem 0;
            }

            &:nth-child(even) {
                flex-direction: row-reverse;
                @include responsive-for(sm) {
                    flex-direction: column;
                    padding: 2rem 0;
                }
            }

            &-details {
                flex: 1 1 55%;
                padding: 0 1rem;
            }

            &-image {
                flex: 1 1 45%;
                padding: 0 1rem;

                img {
                    width: 100%;
                }
            }
        }
    }

    &-content {
        max-width: 100% !important;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    &-images {
        margin: 2rem 0 4rem 0;
        display: flex;
        flex-direction: row;

        @include responsive-for(sm) {
            // flex-direction: column;
            // padding: 2rem 0;
            flex-wrap: wrap;
        }
        &-container {
            // flex: 1 1 33%;
            padding: 0.5rem;

            img {
                width: 100%;
                object-fit: cover;
            }
        }
    }
}
