.breadcumb {
    height: 300px;
    display: flex;
    align-items: center;

    @include responsive-for(sm) {
        height: 200px;
    }

    &-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        &-breadcumb {
            color: #fff;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            .home-link {
                text-decoration: none;
                color: #fff;

                &:hover {
                    color: color(primary, base);
                }
            }
            .page-name {
            }
        }

        &-header {
            color: #fff;
            margin: 0;
            padding: 0;
        }
    }
}
