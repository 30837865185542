.carousel-image-item {
  position: relative;
  height: 450px;
  width: 100%;
  @include responsive-for(sm) {
    height: 150px;
  }
  img {
    height: 100%;
    width: 100% !important;
    object-fit: cover;
    object-position: left bottom;
  }
}
