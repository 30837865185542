.contact {
  margin: 3rem 0;
  &-form {
    margin-bottom: 3rem;
    border: 1px solid #e5e5e5;
    padding: 2rem;
    border-radius: 4px;

    @include responsive-for(lg) {
      margin: 2rem 0;
    }
    h2 {
      font-size: 24px;
      line-height: 2rem;
      font-weight: 700;
      // margin: 0;
    }
    .header-message {
      color: color(body-text);
      font-size: 12px;
      padding: 0;
      margin: 0;
    }

    .submit-success {
      margin-top: 2rem;
      background-color: #d1e7dd;
      padding: 1rem;
      border: 1px solid #badbcc;
      border-radius: 6px;
      p {
        color: #0f5132;
        margin: 0;
        padding: 0;
      }
    }
    &-main {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &-item {
        width: 100%;
        label {
          font-size: 0.9rem;
          display: flex;
          margin-bottom: 0.3rem;
        }
        input,
        textarea {
          font-size: 14px;
          width: 100%;
          border-radius: 4px;
          border: 1px solid #e5e7f3;
          background: #f7f9fb;
          padding: 0.5rem;
          color: #7a7c7f;
          margin-right: 0.5rem;
          font-size: 0.9rem;
          transition: all 300ms;
          &::placeholder {
            font-size: 12px;
          }
          &:focus {
            border-color: #9fa0a6;
            outline: none;
          }
          // @include responsive-for(lg) {
          //   width: 15rem;
          // }
        }
        &--message {
          width: 100%;
        }
        span {
          display: flex;
          font-size: 0.75rem;
          color: red;
        }
      }

      &-row {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        @include responsive-for(sm) {
          flex-direction: column;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.6rem;
        background-color: color(primary, base);
        color: white;
        border: none;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 1px;
        transition: all 200ms;
        border-radius: 25px;

        &:disabled {
          background-color: color(primary, disabled);
        }

        &:not(:disabled):hover {
          background-color: color(primary, hover);
        }

        // @include responsive-for(lg) {
        //   width: 15rem;
        // }
      }
    }
  }
  &-map {
    display: flex;
    flex-direction: column;
    height: 100%;
    // max-width: 100%;

    border: 1px solid #e5e5e5;
    padding: 0.5rem;
    border-radius: 4px;

    h2 {
      font-size: 24px;
      line-height: 2rem;
      font-weight: 700;
    }

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
    @include responsive-for(sm) {
      height: 25rem;
    }
  }
}

.contact-card {
  max-width: 550px;
  // text-align: center;
  border: 1px solid #e5e5e5;
  padding: 1.5rem 2rem 3rem 2rem;
  border-radius: 4px;

  .bookmark-icon {
    text-align: center;

    font-size: 28px;
    color: color(body-text);
  }

  h3 {
    text-align: center;

    margin: 2rem 0 0.3rem 0;
    font-weight: 600;
    font-size: 28px;
    padding: 0;
  }

  &-address {
    text-align: center;

    font-size: 14px;
    margin-bottom: 1rem;
    color: color(body-text);
  }

  .contact-item {
    display: flex;
    justify-content: center;
    gap: 10px;
    font-size: 14px;

    &-title {
      font-weight: 600;
    }
    &-content {
      a {
        text-decoration: none;
        color: color(body-text);
      }
    }
  }
}
