.scrolltop {
  background: color(primary, base);
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  display: inline;
  position: fixed;
  border: none;
  outline: none;
  border-radius: 50%;
  bottom: 3rem;
  right: 3rem;

  @include responsive-for(sm) {
    right: 1.5rem;
  }
  display: inline-block;
  transition: visibility 0.5s ease-in-out;
  box-shadow: 1px 2px 5px rgba(139, 139, 139, 0.637);
  z-index: 99;
}

.notVisible {
  display: none;
  transition: visibility 0.5s ease-in-out;
}
