.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.info-container {
    margin: 4rem 0;
    .body-content {
        font-size: 14px;
        .sub-header {
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.error {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &-image {
        margin: auto;
        max-width: 100%;

        @include responsive-for(sm) {
            height: 256px;
            width: 256px;
        }
    }
}
