.about-us {
    padding: 6rem 0 0 0;

    @include responsive-for(sm) {
        padding: 3rem 0 0 0;
    }

    &-image {
        width: 100%;
        img {
            width: 100%;
        }
    }

    &-body {
        margin-left: 2rem;

        @include responsive-for(sm) {
            margin-left: 0;
            margin-top: 2rem;
        }
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-self: center;
        justify-content: center;

        &-header {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .info {
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;

                .line {
                    width: 40px;
                    height: 2px;
                    background-color: color(primary, base);
                }

                span {
                    width: max-content;
                    color: color(primary, base);
                    font-weight: 600;
                    font-size: 14px;
                }
            }

            h2 {
                font-size: 40px;
                font-weight: 600;
                color: color(primary-text);
            }
        }

        .content {
            div {
                // text-align: center;
                color: color(body-text) !important;
                margin: 0;
            }
        }
    }
}

.members {
    padding: 4rem 0;

    h2 {
        font-size: 32px;
        font-weight: 700;
        text-align: center;
    }
    p {
        font-weight: 300;
        text-align: center;
        color: color(body-text);
        margin-bottom: 2rem;
    }
    &-card {
        display: flex;
        flex-direction: column;
        margin: 0 1rem;
        border-radius: 8px;
        outline: 1px solid rgba(57, 57, 57, 0.3);

        height: 100%;
        max-width: 300px;
        align-self: center;

        @include responsive-for(sm) {
            height: auto;
            margin: auto;
        }

        img {
            width: 100%;
            height: 250px;
            object-fit: cover;
            object-position: center top;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        &-information {
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.8rem;
            &-header {
                // text-align: center;
                h4 {
                    font-size: 24px;
                    font-weight: 600;
                    margin: 0;
                    padding: 0;
                }
                h5 {
                    font-size: 16px;
                    font-weight: 500;

                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}
