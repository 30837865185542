.home-featured {
    padding: 1rem 0 4rem 0;
    .featured-card {
        margin: 1rem auto;
        min-height: 400px;
        display: flex;
        height: 100%;
        flex-direction: column;
        gap: 24px;
        transition: all 200ms;
        padding: 32px 50px 40px;
        align-items: center;
        text-align: center;
        border: 1px solid rgb(204, 204, 204);
        border-radius: 4px;

        @include responsive-for(sm) {
            max-width: 320px;
        }

        &:hover {
            box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.08);
        }

        &-image {
            img {
                max-height: 60px;
                max-width: 100%;
            }
        }

        &-header {
            font-size: 20px;
            font-weight: 700;
            color: color(primary-text);
        }

        &-body {
            color: color(body-text);
            line-height: 160%;
        }

        &-link {
            margin-top: auto;
            color: color(primary, base);
            display: flex;
            gap: 10px;
            align-items: center;
            font-weight: 500;
            text-decoration: none;
        }
    }
}
