.footer {
  background-color: color(footer-bg);
  margin-top: auto;

  &-main {
    padding-top: 3rem;
    padding-bottom: 4rem;

    @include responsive-for(xs) {
      padding-right: 1rem;
      padding-left: 1rem;
      padding-bottom: 2rem;
    }
  }

  &-secondary {
    background-image: url(../assets/footer-bg.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    padding: 1rem 0;

    &-main {
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @include responsive-for(xs) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        padding: 1rem;
      }

      .copy-info {
        color: #fff;
        font-size: 14px;
        font-weight: 300;
      }

      .right {
        padding: 0;
        display: flex;
        gap: 1.5rem;
        justify-content: flex-start;
        align-items: center;

        .link {
          color: #fff;
          font-size: 14px;
          transition: all 0.3s;
          font-weight: 300;

          &:hover {
            color: color(primary, base);
          }
        }
      }
    }
  }

  &__item {
    color: #fff;

    @include responsive-for(sm) {
      margin-bottom: 2rem;
    }

    p {
      margin-top: 2rem;
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      text-align: justify;
      // margin-right: 1rem;
    }

    &-contact {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      font-weight: 300;

      .company-name {
        font-size: 14px;
        font-weight: 600;
        color: #fff;
      }

      .location-info {
        color: #fff;
      }

      .phone-no {
        color: #fff;

        a {
          color: #fff;
          &:hover {
            border-bottom: 1px solid white;
          }
        }

        margin-top: 1rem;
      }
    }

    &-heading {
      border-bottom: 1px solid white;
      margin-bottom: 1.5rem;

      h4 {
        color: #fff;

        padding: 0.5rem 0;
        font-size: 20px;
        font-weight: 600;
        font-weight: 500;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 1rem;
        svg {
          font-size: 18px;
        }
        a {
          color: #cccccc;
          transition: all 200ms;
          padding-bottom: 2px;
          &:hover {
            color: #fff;

            border-bottom: 1px solid white;
          }
        }
      }
    }

    &-socials {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      a {
        color: #8998c2;
        font-size: 20px;
        transition: all 0.3s;

        &:hover {
          color: color(primary, base);
        }
      }
    }
  }
}
