* {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

svg:not(:root) {
  overflow: unset;
}
html {
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
}

div {
  color: color(primary-text);
}

body {
  margin: 0;
  font-weight: 400;
  background: color(background);
  color: color(primary-text);
}

h1 {
  font-size: $h1;
  line-height: 68px;
  font-weight: 700 !important;
  @include responsive-for(md) {
    font-size: 2.75rem;
    line-height: 3rem;
  }
}
h2 {
  font-size: $h2;

  font-weight: 700 !important;
  color: color(primary-text);
}
h3 {
  font-size: $h3;
  font-weight: 700 !important;
  color: color(primary-text);
}
h4 {
  font-size: $h4;
  font-weight: 700 !important;

  color: color(primary-text);
}

button,
textarea,
input {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

a {
  text-decoration: none !important;
  cursor: pointer;
  &:hover {
    color: black;
  }
}

thead {
  color: white;
  border-style: hidden !important;
}

th {
  font-weight: 500;
  font-size: 20;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}
td {
  border: none !important;
  padding: 0.7rem !important;
}

.red {
  color: #e9292f;
}

p {
  color: color(body-text);
}
